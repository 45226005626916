import React from 'react';
import { SEO, NotFound } from '@components';

const NotFoundPage = () => (
  <>
    <SEO title="404: Not found" />
    <NotFound />
  </>
);

export default NotFoundPage;
